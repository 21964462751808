/*
 * Fonts
 * Work Sans (300, 400, 500)
 * https://fonts.google.com/specimen/Work+Sans
 */
@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Work Sans Light'), local('WorkSans-Light'), url(../../fonts/WorkSans-Light.woff2) format('woff2'), url(../../fonts/WorkSans-Light.woff) format('woff');
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Work Sans'), local('WorkSans-Regular'), url(../../fonts/WorkSans-Regular.woff2) format('woff2'), url(../../fonts/WorkSans-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Work Sans Medium'), local('WorkSans-Medium'), url(../../fonts/WorkSans-Medium.woff2) format('woff2'), url(../../fonts/WorkSans-Medium.woff) format('woff');
}